import type { ReactElement } from 'react'

export default function Motif({
  className,
  style,
  width,
  height,
  viewBox,
}: {
  className?: string
  style?: any
  width?: string
  height?: string
  viewBox?: string
  strokeWidth?: number
}): ReactElement {
  return (
    <svg
      className={className}
      style={style}
      width={width || '36'}
      height={height || '36'}
      viewBox={viewBox || '0 0 36 36'}
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1558 7.89825C10.9673 6.48181 8.6595 7.20944 8.49834 9.05146L7.2586 23.2218C7.06644 25.4182 8.69119 27.3545 10.8876 27.5466L23.2983 28.6324C25.4947 28.8246 27.431 27.1998 27.6232 25.0035L28.8629 10.8331C29.0241 8.99112 26.8777 7.87379 25.4613 9.06234L22.04 11.9331C21.6478 12.2622 21.4517 12.4267 21.366 12.6287C21.2906 12.8064 21.2733 13.0034 21.3167 13.1915C21.3661 13.4053 21.5306 13.6014 21.8597 13.9936L22.5526 14.8194C25.7544 18.6351 22.7445 24.4172 17.7823 23.9831L17.1271 23.9257C12.165 23.4916 10.2048 17.2747 14.0205 14.0729L14.8464 13.38C15.2385 13.0509 15.4346 12.8864 15.5203 12.6844C15.5958 12.5067 15.613 12.3097 15.5696 12.1216C15.5202 11.9078 15.3557 11.7117 15.0266 11.3196L12.1558 7.89825ZM19.7802 9.24002C19.388 9.56908 19.1919 9.7336 18.9781 9.78297C18.79 9.82639 18.593 9.80916 18.4153 9.73373C18.2133 9.64799 18.0488 9.45191 17.7197 9.05975L14.8489 5.63845C11.6471 1.82268 5.43023 3.78288 4.99609 8.74505L3.75635 22.9154C3.39497 27.046 6.45054 30.6875 10.5812 31.0489L22.9919 32.1347C27.1225 32.4961 30.764 29.4405 31.1254 25.3099L32.3652 11.1395C32.7993 6.17734 27.0172 3.16741 23.2015 6.36921L19.7802 9.24002ZM19.1666 16.2534C18.8375 15.8612 18.673 15.6651 18.471 15.5794C18.2933 15.504 18.0963 15.4867 17.9082 15.5301C17.6944 15.5795 17.4983 15.744 17.1062 16.0731L16.2803 16.766C14.8639 17.9546 15.5915 20.2623 17.4335 20.4235L18.0887 20.4808C19.9307 20.642 21.0481 18.4956 19.8595 17.0792L19.1666 16.2534Z"
        fill="url(#paint0_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="5.48437"
          y1="28.1953"
          x2="31.8516"
          y2="6.9961"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EB4A54" />
          <stop offset="1" stopColor="#A6009D" />
        </linearGradient>
      </defs>
    </svg>
  )
}
