import { Popover as HUIPopover, Transition } from '@headlessui/react'
import type { Placement } from '@popperjs/core'
import Portal from '@reach/portal'
import cn from 'classnames'
import type { ButtonHTMLAttributes, FC, ReactNode } from 'react'
import { useCallback, useRef, useState } from 'react'
import { usePopper } from 'react-popper'

import ArrowDown from '@components/icons/ArrowDown'
import emitter, { EVENT_MENU_OPEN } from '@lib/events'

import s from './Popover.module.css'

export interface PopoverProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  component?: string | ReactNode
  className?: string
  variant?: 'text' | 'bordered' | 'plain'
  size?: 'xs' | 'sm' | 'base' | 'lg'
  placement?: Placement
  offsetX?: number
  offsetY?: number
  transitionClassName?: string
  above?: boolean
  right?: boolean
  includeArrow?: boolean
  active?: boolean
  loading?: boolean
  disabled?: boolean
  noStyle?: boolean
  onOpen?: () => void
}

const Popover: FC<PopoverProps> = (props) => {
  const {
    component,
    className,
    variant = 'bordered',
    size = 'base',
    above = false,
    right = false,
    includeArrow = true,
    children,
    disabled = false,
    noStyle = false,
    placement = 'bottom-start',
    offsetX = 0,
    offsetY = 0,
    transitionClassName = '',
    onOpen = () => {
      // Do nothing
    },
  } = props
  const isRichComponent = typeof component !== 'string'
  const currentEmittedValued = useRef(false)
  const popperRef = useRef(null)
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(
    null
  )
  const [popperElement, setPopperElement] = useState<any>(null)

  const { styles, attributes } = usePopper(targetElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [offsetX, offsetY],
        },
      },
    ],
  })

  const setOpen = useCallback(
    (open: boolean) => {
      if (currentEmittedValued.current === open) {
        return
      }
      onOpen?.()
      emitter.emit(EVENT_MENU_OPEN, { open })
      currentEmittedValued.current = open
    },
    [onOpen]
  )

  return (
    <HUIPopover>
      {({ open }) => {
        setOpen(open)
        return (
          <div ref={setTargetElement} className="relative z-10">
            <HUIPopover.Button
              disabled={disabled}
              className={cn({
                'flex-center flex focus:outline-none': noStyle,
                'group inline-flex w-full select-none rounded-md border text-sm font-medium leading-5 transition duration-200  ease-in-out focus:outline-none':
                  !noStyle,
                'px-2 py-1': size === 'sm' && !isRichComponent && !noStyle,
                'px-4 py-[7px]': size !== 'sm' && !isRichComponent && !noStyle,
                'p-1': isRichComponent && !noStyle,
                'border-transparent bg-white text-neutral-700 focus:bg-neutral-100 focus:text-neutral-900':
                  variant === 'text' && !noStyle,
                'hover:bg-neutral-100 hover:text-neutral-900':
                  !disabled && variant === 'text' && !noStyle,
                'border-neutral-200 bg-neutral-50 text-neutral-700':
                  variant === 'bordered' && !noStyle,
                'ring-offset-2 hover:bg-neutral-100 hover:text-neutral-900 focus:ring-2 focus:ring-neutral-400':
                  variant === 'bordered' && !noStyle && !disabled,
                'cursor-not-allowed': disabled,
                'active:bg-neutral-50 active:text-neutral-800': !disabled,
              })}
            >
              {!isRichComponent ? (
                <span className="truncate">{component}</span>
              ) : (
                component
              )}
              {includeArrow && (
                <ArrowDown className="ml-1 -mr-1 h-5 w-5 text-neutral-300 group-hover:text-neutral-400 group-focus:text-neutral-400" />
              )}
            </HUIPopover.Button>

            <Portal>
              <div
                ref={popperRef}
                style={styles.popper}
                className="z-20"
                {...attributes.popper}
              >
                <Transition
                  show={open}
                  enter="transition ease-out duration-75"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  className={cn(transitionClassName, {
                    'absolute bottom-full': above,
                    'absolute right-0': right,
                  })}
                  beforeEnter={() => {
                    setPopperElement(popperRef.current)
                  }}
                  afterLeave={() => {
                    setPopperElement(null)
                  }}
                >
                  <HUIPopover.Panel
                    static
                    className={cn(className, s.popover, {
                      [s.popoverBelowLeft]: !above && !right,
                      [s.popoverBelowRight]: !above && right,
                    })}
                  >
                    <div className="overflow-hidden rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5">
                      {children}
                    </div>
                  </HUIPopover.Panel>
                </Transition>
              </div>
            </Portal>
          </div>
        )
      }}
    </HUIPopover>
  )
}

export default Popover
