import type { ReactElement } from 'react'

export default function ArrowDown({
  className,
  width,
  height,
  viewBox,
  strokeWidth,
}: {
  className?: string
  width?: string
  height?: string
  viewBox?: string
  strokeWidth?: number
}): ReactElement {
  return (
    <svg
      className={className}
      width={width || '20'}
      height={height || '20'}
      viewBox={viewBox || '0 0 20 20'}
      fill="none"
      strokeWidth={typeof strokeWidth !== 'undefined' ? strokeWidth : 2}
    >
      <path d="M6 8L10 12L14 8" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
