import ChevronDown from '@components/icons/ChevronDown'
import Popover from '@components/ui/Popover'

export const PanelItem = ({
  item,
  hasIcon,
  hasDescription,
}: {
  item: any
  hasIcon: boolean
  hasDescription: boolean
}) => {
  return (
    <a
      key={item.name}
      href={item.href}
      className="group flex w-full items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-slate-50 focus:outline-none focus-visible:ring focus-visible:ring-sky-500 focus-visible:ring-opacity-50"
    >
      {!!item.icon && (
        <div
          className={`flex flex-shrink-0 items-center justify-center rounded-md bg-sky-100 ${
            hasDescription
              ? 'h-10 w-10 sm:h-12 sm:w-12'
              : 'h-8 w-8 sm:h-10 sm:w-10'
          }`}
        >
          <item.icon
            className={`${hasDescription ? 'h-6 w-6' : 'h-5 w-5'} text-sky-500`}
            aria-hidden="true"
          />
        </div>
      )}
      <div className={hasIcon ? 'ml-4' : 'ml-1'}>
        <span className="block text-sm font-medium text-slate-900">
          {item.name}
        </span>
        {item.description && (
          <span className="block text-sm text-slate-500 antialiased">
            {item.description}
          </span>
        )}
      </div>
    </a>
  )
}

export const PublicNavbarPopover = ({ items, buttonClassName }: any) => {
  return (
    <Popover
      className="w-[360px]"
      size="sm"
      variant="text"
      noStyle
      placement="bottom-start"
      includeArrow={false}
      offsetY={-1}
      onOpen={() => {
        //
      }}
      component={
        <a className={`outline-none, ${buttonClassName}`}>
          <div className="flex flex-row items-center">
            Use cases
            <ChevronDown
              className={
                'ml-2 -mr-1 -mt-1 -mb-1 h-5 w-5 text-neutral-400 opacity-50 transition duration-150 ease-in-out group-hover:opacity-100'
              }
              aria-hidden="true"
            />
          </div>
        </a>
      }
    >
      <div
        style={{ maxHeight: 'calc(100vh - 80px)' }}
        className="scrollbar-none flex flex-col gap-3 overflow-hidden overflow-y-auto px-3 py-3"
      >
        {(items || []).map((item: any, i: number) => (
          <PanelItem
            key={`popover-item-${i}`}
            item={item}
            hasIcon={true}
            hasDescription={true}
          />
        ))}
      </div>
    </Popover>
  )
}
