export const MOTIF_APP_DOWNLOAD_URL = 'https://dl.todesktop.com/2012134t6as1nuw'
export const MOTIF_DISCORD_INVITE_URL = 'https://discord.gg/MBMh4apz6X'
export const MOTIF_DOCS_API_URL = 'https://motif.land/docs/advanced/api'

export const MOTIF_AUTH_TOKEN_NAME = '__motif_auth_token__'
export const MOTIF_CONTAINER_ID = '__MOTIF_CONTAINER_ID__'
export const MOTIF_JS_META_VAR_NAME = '__MOTIF_JS_META__'
export const MOTIF_FILES_VAR_NAME = '__MOTIF_FILES__'
export const MOTIF_TEMP_EXPORTED_META_VAR_NAME = '__MOTIF_TEMP_EXPORTED_META__'
export const MOTIF_TEMPLATE_NAME = '__MOTIF_TEMPLATE__'

export const PREVIEW_SUBDOMAIN_EXTENSION = '-preview'
export const PREVIEW_SUBDOMAIN_EXTENSION_RE = /-preview$/

export const TAILWIND_CONFIG_BASE_FILENAME = 'tailwind.config'
export const MARKDOC_CONFIG_BASE_FILENAME = 'markdoc.config'
export const MARKDOC_COMPONENTS_BASE_FILENAME = 'markdoc.components'

export const MOTIF_CONFIG_FILENAME = 'motif.json'
export const MOTIF_FOLDER_NAME = 'motif'

export const MOTIF_IMG_TAG = '__motif_img__'
export const MOTIF_A_TAG = '__motif_a__'

export const ESM_SH_BASE_URL = 'https://esm.sh'
export const SKYPACK_CDN_BASE_URL = 'https://cdn.skypack.dev'

export const MOTIF_WEBRTC_SIGNALING_SERVERS = [
  // 'ws://localhost:8080',
  'wss://motif-signaling.fly.dev/',
  // 'wss://signaling.yjs.dev',
  // 'wss://y-webrtc-signaling-us.herokuapp.com',
  // 'wss://y-webrtc-signaling-eu.herokuapp.com',
]

export const FILE_YDOC_ROOM_PREFIX = 'motif-file-ydoc-'
export const FILE_VERSIONS_YDOC_ROOM_PREFIX = 'motif-file-versions_ydoc-'
export const PROJECT_TREE_DATA_ROOM_PREFIX = 'motif-project-tree-data-ydoc-'
export const FILE_CONTENT_ROOM_PREFIX = 'motif-file-content-ydoc-'

export const SUBDOMAINS_BLACKLIST = [
  'admin',
  'api',
  'cdn',
  'dashboard',
  'importer',
  'docs',
  'dev',
  'email',
  'help',
  'link',
  'motif',
  'prod',
  'production',
  'play',
  'support',
  'web',
  'www',
  // Start: reserved for Sendgrid
  '19689548',
  'url541',
  's1._domainkey',
  's2._domainkey',
  'em6775',
  // End: reserved for Sendgrid
]

export const MODULE_NAME_BLACKLIST = ['motif']
export const HANDLE_BLACKLIST = ['motif']

export const MEDIA_MIN_WIDTH_SM = '(min-width: 640px)'
export const MEDIA_MIN_WIDTH_MD = '(min-width: 768px)'
export const MEDIA_MIN_WIDTH_LG = '(min-width: 1024px)'
export const MEDIA_MIN_WIDTH_XL = '(min-width: 1280px)'
export const MEDIA_MIN_WIDTH_2XL = '(min-width: 1536px)'

export const SUPPORT_EMAIL = 'support@motif.land'

export const SUPERADMIN_EMAILS = ['michael@motif.land']

export const isMotifSuperAdmin = (email: string) => {
  return email && SUPERADMIN_EMAILS.includes(email)
}

// Fix to 3.5MB to be sure we are below the Vercel limit (4MB currently)
// Note that we keep 500Kb extra space for additional project data, such
// as project metadata and folders, since this will be included in the
// first request.
export const MAX_VERCEL_RESPONSE_SIZE = 3500000

// Fix to 980KB to be sure we are below the Vercel limit (1MB currently)
export const MAX_VERCEL_UPLOAD_SIZE = 980000

// Max number of mutations to send, to ensure they can all be processed
// within the Vercel 60s serverless function time limit.
export const MAX_MUTATIONS_TO_PUSH = 40

export const GPTSEARCH_I_DONT_KNOW = 'Sorry, I am not sure how to answer that.'
