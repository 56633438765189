import mitt from 'mitt'

export const EVENT_FILES_INIT_CONTENT_UPDATE = 'EVENT_FILES_INIT_CONTENT_UPDATE'
export const EVENT_FILE_CONTENT_UPDATE = 'EVENT_FILE_CONTENT_UPDATE'
export const EVENT_CREATE_FOLDER = 'EVENT_CREATE_FOLDER'
export const EVENT_CREATE_FILE = 'EVENT_CREATE_FILE'
export const EVENT_CANCEL_CREATE_FOLDER = 'EVENT_CANCEL_CREATE_FOLDER'
export const EVENT_DID_CHANGE_PAGE_TITLE = 'EVENT_DID_CHANGE_PAGE_TITLE'
export const EVENT_MARK_FOR_DELETION = 'EVENT_MARK_FOR_DELETION'
export const EVENT_SCROLL_TO_FILE = 'EVENT_SCROLL_TO_FILE'
export const EVENT_DID_REQUEST_EDIT_NAME = 'EVENT_DID_REQUEST_EDIT_NAME'
export const EVENT_MENU_OPEN = 'EVENT_MENU_OPEN'
export const EVENT_OPEN_SEARCH = 'EVENT_OPEN_SEARCH'
export const EVENT_OPEN_LIBRARY = 'EVENT_OPEN_LIBRARY'
export const EVENT_OPEN_PROJECT_MENU = 'EVENT_OPEN_PROJECT_MENU'
export const EVENT_OPEN_SPACE_MENU = 'EVENT_OPEN_SPACE_MENU'
export const EVENT_AWARENESS_DID_CHANGE = 'EVENT_AWARENESS_DID_CHANGE'
export const EVENT_IS_VIEWING_FILE = 'EVENT_IS_VIEWING_FILE'
export const EVENT_DID_INITIALIZE_ESBUILD = 'EVENT_DID_INITIALIZE_ESBUILD'
export const EVENT_PROGRAMMATIC_DOC_UPDATE = 'EVENT_PROGRAMMATIC_DOC_UPDATE'
export const EVENT_PROGRAMMATIC_DOC_CLEAR = 'EVENT_PROGRAMMATIC_DOC_CLEAR'
export const EVENT_NOTIFY_CONTENT_UPDATE = 'EVENT_NOTIFY_CONTENT_UPDATE'
export const EVENT_NOTIFY_COMMAND_S = 'EVENT_NOTIFY_COMMAND_S'
export const EVENT_NOTIFY_DIRTY = 'EVENT_NOTIFY_DIRTY'
export const EVENT_DID_RENDER_FILE = 'EVENT_DID_RENDER_FILE'
export const EVENT_REQUEST_EDITOR_FOCUS = 'EVENT_REQUEST_EDITOR_FOCUS'
export const EVENT_EDITOR_MOVE_CURSOR_BY = 'EVENT_EDITOR_MOVE_CURSOR_BY'
export const EVENT_EDITOR_MOVE_CURSOR_TO = 'EVENT_EDITOR_MOVE_CURSOR_TO'
export const EVENT_EDITOR_SCROLL_TO_LINE = 'EVENT_EDITOR_SCROLL_TO_LINE'
export const EVENT_INSERT_LOCAL_COMPONENT = 'EVENT_INSERT_LOCAL_COMPONENT'
export const EVENT_INSERT_REMOTE_COMPONENT = 'EVENT_INSERT_REMOTE_COMPONENT'
export const EVENT_BRING_EDITOR_IN_FOCUS = 'EVENT_BRING_EDITOR_IN_FOCUS'
export const EVENT_REPLICACHE_UPDATE_SYNC_STATE =
  'EVENT_REPLICACHE_UPDATE_SYNC_STATE'
export const EVENT_REPLICACHE_REMOTE_FILES_CHANGED =
  'EVENT_REPLICACHE_REMOTE_FILES_CHANGED'
export const EVENT_PUSHER_POKE = 'EVENT_PUSHER_POKE'

export const AWARENESS_EVENT_IS_VIEWING_FILE = 'AWARENESS_EVENT_IS_VIEWING_FILE'

const emitter = mitt()

export default emitter
