import { Disclosure } from '@headlessui/react'
import cn from 'classnames'
import Link from 'next/link'
import type { FC } from 'react'

import { PublicNavbarPopover } from '@components/common/PublicNavbar/PublicNavbarPopover'
import ChevronDown from '@components/icons/ChevronDown'
import Motif from '@components/icons/Motif'

interface PublicNavbarProps {
  className?: string
  items?: any
  title?: string
  path?: string
  titleHref?: string
}

const navigation = [{ title: 'Request access', href: '/early-access' }]

const PublicNavbar: FC<PublicNavbarProps> = ({ path, title, titleHref }) => {
  return (
    <Disclosure as="nav" className="border-b border-dashed bg-white">
      {({ open }) => (
        <>
          <div className="hidden border-b border-dashed" />
          <div
            className={cn('mx-auto px-4 antialiased sm:px-6 md:px-8', {
              'bg-white': open,
            })}
          >
            <div className="relative flex h-16 items-center justify-between">
              <div className="flex flex-1 items-center justify-center">
                <div className="mt-[1px] flex flex-shrink-0 flex-row items-center gap-3 pl-1 sm:pl-0">
                  <Link
                    href="/"
                    className="bounce flex-none transform hover:-rotate-12"
                  >
                    <Motif className="h-8 w-8" />
                  </Link>
                  <Link
                    href={titleHref || '/'}
                    className="font-semibold text-slate-900 antialiased transition hover:opacity-80"
                  >
                    {title || 'Motif'}
                  </Link>
                </div>
                <div className="flex-grow" />
                <div
                  className={cn(
                    'hidden flex-grow items-center justify-end sm:mr-4 sm:flex'
                  )}
                >
                  <div className="align-center flex space-x-1">
                    {navigation.map((item: any) => {
                      const active = path?.startsWith(
                        item.baseHref || item.href
                      )
                      const _className = cn(
                        'whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-slate-400 focus:ring-white text-sm px-2',
                        {
                          'text-sky-500 hover:text-sky-500': active,
                          'hover:text-slate-900': !active,
                          'bg-neutral-200 text-white': item.current,
                          'text-slate-900 hover:bg-slate-100 transition duration-200 py-2 rounded-md font-medium':
                            !item.current,
                        }
                      )
                      const content = item?.Icon ? (
                        <item.Icon className="h-5 w-5 text-slate-900 sm:h-6 sm:w-6" />
                      ) : (
                        item.title
                      )
                      if (item.menuItems?.length > 0) {
                        return (
                          <PublicNavbarPopover
                            buttonClassName={_className}
                            placement="bottom"
                            width={400}
                            Button={
                              <span
                                className={`${_className} flex flex-row items-center gap-1`}
                              >
                                Use cases{' '}
                                <ChevronDown className="-mr-1 h-5 w-5 flex-none text-slate-300" />
                              </span>
                            }
                            items={item.menuItems}
                          />
                        )
                      } else {
                        return (
                          <a
                            key={item.title}
                            href={item.href}
                            target={item.target}
                            className={_className}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {content}
                          </a>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
              <div className="ml-2 flex items-center sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-neutral-400 transition duration-200 ease-in-out hover:bg-slate-100 hover:text-neutral-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {/* {open ? (
                    <XIcon
                      className={cn('block h-6 w-6 text-neutral-400')}
                      aria-hidden="true"
                    />
                  ) : (
                    <MenuIcon
                      className={cn('block h-6 w-6 text-neutral-400')}
                      aria-hidden="true"
                    />
                  )} */}
                </Disclosure.Button>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div
              style={{ paddingBottom: '0.5rem' }}
              className="mb-2 space-y-1 px-4 pt-2 pb-4"
            >
              {/* {navigation.map((item) => (
      <MobileNavItem item={item} />
    ))} */}
            </div>
            <div className="hidden bg-sky-100" />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default PublicNavbar
